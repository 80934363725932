import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';

import Paper from '@glass/web/components/base/Paper';
import makeStyles from '@glass/web/modules/theme/makeStyles';

import SearchBar from '@glass/shared/components/Search/SearchBar';

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(1),
    position: 'relative',
    zIndex: 10,
  },
}));

function PaperSearchBar({ inputProps, elevation, onSearchSuccess, autoFocus }) {
  const [value, setValue] = useState('');
  const { classes } = useStyles();

  const handleChange = useCallback((change) => setValue(change), []);

  return (
    <Paper className={classes.root} elevation={elevation}>
      <SearchBar
        autoFocus={autoFocus}
        textFieldProps={{
          inputProps,
        }}
        value={value}
        onChange={handleChange}
        onSearchSuccess={onSearchSuccess}
      />
    </Paper>
  );
}

PaperSearchBar.propTypes = {
  autoFocus: PropTypes.bool,
  elevation: PropTypes.number,
  inputProps: PropTypes.shape({
    id: PropTypes.string,
  }),
  onSearchSuccess: PropTypes.func,
};

PaperSearchBar.defaultProps = {
  elevation: 2,
};

export default PaperSearchBar;
