import PropTypes from 'prop-types';
import React from 'react';

import { MD } from '@glass/web/modules/theme/breakpointKeys';
import { CENTER } from '@glass/web/modules/theme/constants';
import { FLEX } from '@glass/web/modules/theme/flexConstants';
import makeStyles from '@glass/web/modules/theme/makeStyles';
import { DEFAULT_SPACING, EXTRA_SMALL_SPACING } from '@glass/web/modules/theme/spacing';

import HeaderSpacer from '@glass/shared/components/Layout/Header/HeaderSpacer';
import usePresentation from '@glass/shared/components/hooks/usePresentation';
import themeBackground from '@glass/shared/modules/theme/styles/themeBackground';

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundImage: themeBackground,
    color: theme.palette.common.white,
    backgroundColor: '#512da8',
    display: FLEX,
    flexDirection: 'column',
  },
  container: {
    display: FLEX,
    alignItems: CENTER,
  },
  presentationContainer: {
    paddingTop: `0!important`,
    paddingBottom: `0!important`,
  },
  hero: {
    paddingTop: theme.spacing(DEFAULT_SPACING),
    paddingBottom: theme.spacing(DEFAULT_SPACING),
    [theme.breakpoints.down(MD)]: {
      // this paddingTop an exception for hero sections
      paddingTop: theme.spacing(EXTRA_SMALL_SPACING),
      paddingBottom: theme.spacing(EXTRA_SMALL_SPACING),
    },
    flexGrow: 1,
  },
  presentation: {
    height: '100vh',
  },
  noBottomPadding: {
    paddingBottom: '0px!important',
  },
}));

const Cover = React.forwardRef(
  ({ id, children, className, containerClassName, hero, noBottomPadding }, ref) => {
    const { classes, cx } = useStyles();
    const isPresentation = usePresentation();
    return (
      <div
        ref={ref}
        className={cx(
          className,
          classes.root,
          hero && isPresentation ? classes.presentation : null,
        )}
        id={id}
      >
        {hero && !isPresentation ? <HeaderSpacer /> : null}
        <div
          className={cx(
            classes.container,
            containerClassName,
            hero ? classes.hero : null,
            noBottomPadding ? classes.noBottomPadding : null,
            hero && isPresentation ? classes.presentationContainer : null,
          )}
        >
          {children}
        </div>
      </div>
    );
  },
);

Cover.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  hero: PropTypes.bool,
  id: PropTypes.string,
  noBottomPadding: PropTypes.bool,
};

export default React.memo(Cover);
