import PropTypes from 'prop-types';
import React from 'react';

import { HERO_SEARCH_SECTION_ID } from '@glass/web/modules/content/pages/contentIds';
import { CENTER } from '@glass/web/modules/theme/constants';

import BreadcrumbStructuredData from '@glass/shared/components/Head/BreadcrumbStructuredData';
import Cover from '@glass/shared/components/Section/HeroSection/Cover';
import SearchSection from '@glass/shared/components/Section/SearchSection/SearchSection';
import usePageData from '@glass/shared/modules/pageData/usePageData';
import withTrackingContext from '@glass/shared/modules/tracking/withTrackingContext';

const HeroSearchSection = React.forwardRef(
  (
    { title, titleVariant, titleComponent, subtitleComponent, subtitle, subtitleVariant, type },
    ref,
  ) => {
    const { breadcrumbItems } = usePageData();
    return (
      <Cover ref={ref} hero>
        <BreadcrumbStructuredData items={breadcrumbItems} />
        <SearchSection
          disableCta
          align={CENTER}
          isAlternatingColor={false}
          subtitle={subtitle}
          subtitleComponent={subtitleComponent}
          subtitleVariant={subtitleVariant}
          title={title}
          titleComponent={titleComponent}
          titleVariant={titleVariant}
          type={type}
        />
      </Cover>
    );
  },
);

HeroSearchSection.propTypes = {
  subtitle: PropTypes.node,
  subtitleComponent: PropTypes.elementType,
  subtitleVariant: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  titleComponent: PropTypes.elementType,
  titleVariant: PropTypes.string,
  type: PropTypes.string,
};

HeroSearchSection.defaultProps = {
  titleVariant: 'h1',
  subtitleVariant: 'h3',
  subtitleComponent: 'h2',
  title: 'Find <em>your</em> $(resumeWords.singular)',
};

export default React.memo(withTrackingContext(HeroSearchSection, HERO_SEARCH_SECTION_ID));
