import PropTypes from 'prop-types';
import React from 'react';

import {
  CONTENT_ASSIGNMENTS_CONFIG_KEY,
  CONTENT_ASSIGNMENTS_KEY,
  CONTENT_ASSIGNMENT_SEED_KEY,
  CONTENT_ASSIGNMENT_TRACKING_KEY,
} from '@glass/common/modules/assignments/content/keys';

import ContentAssignmentsContext from '@glass/shared/components/App/context/ContentAssignmentsContext';
import {
  useTrackingPath,
  useTrackingPathProps,
} from '@glass/shared/modules/tracking/useTrackingProp';

const withContentAssignmentsContext = (Component) => {
  function WithContentAssignmentsContext({
    [CONTENT_ASSIGNMENTS_CONFIG_KEY]: {
      [CONTENT_ASSIGNMENTS_KEY]: contentAssignments,
      [CONTENT_ASSIGNMENT_SEED_KEY]: contentAssignmentSeed,
      [CONTENT_ASSIGNMENT_TRACKING_KEY]: contentAssignmentTracking,
    } = {},
    ...otherProps
  }) {
    useTrackingPath(CONTENT_ASSIGNMENT_SEED_KEY, contentAssignmentSeed);
    useTrackingPathProps(contentAssignmentTracking);

    return (
      <ContentAssignmentsContext.Provider value={contentAssignments}>
        <Component {...otherProps} />
      </ContentAssignmentsContext.Provider>
    );
  }

  WithContentAssignmentsContext.propTypes = {
    [CONTENT_ASSIGNMENT_SEED_KEY]: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    [CONTENT_ASSIGNMENT_TRACKING_KEY]: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    [CONTENT_ASSIGNMENTS_KEY]: PropTypes.object,
  };

  WithContentAssignmentsContext.getInitialProps = Component.getInitialProps;

  return React.memo(WithContentAssignmentsContext);
};

export default withContentAssignmentsContext;
